<template>
  <div class="backup">
    <transition name="fade" mode="out-in">
      <div class="backup__content">
        <div class="backup__form">
          <div class="l-col">
            <p v-if="countOfDeleteObjectsIsOne" class="standart-text" v-html="textForDelete"></p>
            <div v-if="!countOfDeleteObjectsIsOne" class="backup-block">
              <p v-html="textForDelete" class="standart-text">
                <!--                <label class="standart-text">{{ $t('labelDel') }}</label>-->
                <!--                <label class="standart-text">{{ textForDelete }}</label>-->
              </p>
              <label class="standart-text">{{ $t('labelAlert') }}</label>
              <label class="standart-text">{{ $t('labelSure') }}</label>
            </div>
            <base-input
              v-model="nameStorage"
              :placeholder="
                $t('backup.placeholder', {
                  obj: countOfDeleteObjectsIsOne ? 'имя объекта' : 'количество элементов',
                })
              "
              size="small"
              :label="
                $t('backup.label', {
                  obj: countOfDeleteObjectsIsOne
                    ? 'имя удаляемого объекта'
                    : 'количество удаляемых элементов',
                })
              "
              class="backup-form__field backup-form__field--input"
              @input="onChange"
            >
            </base-input>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import setFocus from '@/mixins/setFocus';
import showErrorModal from '@/mixins/showErrorModal';
import updateServerInfo from '@/layouts/VPS/mixins/updateServerInfo';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
export default {
  name: 'DeleteObject',
  components: {
    BaseInput,
  },
  mixins: [setFocus, showErrorModal],
  props: {
    storage: {
      type: Object,
      required: true,
    },
    // item: {
    //   type: String,
    //   required: true,
    // }
  },
  data() {
    return {
      nameStorage: '',
      currentObjects: [],

      timerCnt: 0,
    };
  },
  computed: {
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
    isValid() {
      if (this.countOfDeleteObjectsIsOne) {
        return this.storage.name === this.nameStorage;
      } else {
        return +this.nameStorage === this.countOfDeleteObjects;
      }
    },
    countOfDeleteObjectsIsOne() {
      return this.storage.storage.currentPrefix.length === 1;
    },
    countOfDeleteObjects() {
      return this.storage.storage.currentPrefix.length;
    },
    textForDelete() {
      if (this.storage.name.endsWith('/')) {
        return this.$t('label', { count: this.countOfDeleteObjects, name: this.storage.name });
      } else return this.$t('labelDel', { name: this.storage.name });
    },
    serverId() {
      return this.storage.name;
    },
  },
  mounted() {
    this.$emit('notready');
    // console.log(this.storage.name);
    this.fetchStorage(this.storage.name);
  },
  methods: {
    getS3BucketList() {
      return this.$store.dispatch('moduleStack/getS3BucketList', {
        id: this.storage.id,
      });
    },
    async updateStorageInfo(prefix) {
      const params = {
        storage: this.storage.storage.name,
        currentPrefix: true,
      };
      if (prefix) params.prefix = prefix;
      await this.$store
        .dispatch('moduleStack/fetchCurrentStorages', params)
        // .then(data => console.log(data))
        .then(async () => await this.getS3BucketList())
        .finally(() => {});
    },
    async fetchStorage(item) {
      this.currentObjects = await this.updateStorageInfo(item);
    },
    onChange(event) {
      if (event && this.isValid)
        this.$emit('change', {
          storage: this.storage,
        });
      else this.$emit('notready');
    },
    submit() {
      if (this.isValid) {
        this.$emit('submit', {
          storage: this.storage,
        });
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": "Все объекты (<b>%{count}</b>) с префиксом <b>%{name}</b> будут удалены. ",
    "labelDel": "Удалить <b>%{name}</b>?",
    "labelSure": "Восстановить данные будет невозможно.",
    "labelAlert": "Удаление объектов происходит поочередно и может занять некоторое время. ",
    "confirm": {
      "fail" : "Не совпадает имя объекта - удаление отменено",
      "failSum" : "Не совпадает количество %{object} - удаление отменено"
    },
    "object": " 0 | {n} объект. | {n} объекта. | {n} объектов.",
    "element": " 0 | {n} элемент | {n} элемента | {n} элементов",
    "folder": " 0 |{n} папка | {n} папки | {n} папок",

    "backup": {
      "placeholder": "Введите %{obj} ...",
      "label": "Введите %{obj} для подтверждения:",
      "success": "Объект <b>{name}</b>, успешно удалён."
    },
    "btn": {
      "send": "Удалить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.backup-block {
  display: flex;
  flex-direction: column;
}
.backup-form {
  &__field {
    width: 100%
    &  {
      margin-top: 1.25rem;
    }
  }
}
.backup__send {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;

  &-btn {
    margin-top: 20px
    margin-right: 0;
    +breakpoint(ms-and-up) {
      margin-top: 60px;
    }
  }

  &-container{
    display: flex;
    align-items: flex-end;

  }

}
</style>
